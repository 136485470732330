module.exports = {
  comm: {
    home: 'Acasă',
    pricing: 'Prețuri',
    blog: 'Clonare Voce și API',
    language: 'limbă:',
    select_language: 'selectează limba',
    txt2voice: 'Text în voce',
    voice2txt: 'Voce în text',
    voiceclone: 'Clonare voce',
    video2txt: 'Video în text',
    footer_help: 'Aveți nevoie de ajutor? Trimiteți-ne un email:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Autentificare',
    login_desc: 'Conectați-vă pentru a accesa contul dvs. de Generator de Voce TikTok',
    logout: 'Deconectare',
  },
  txt2voice: {
    title: 'Generator de voce TikTok: Generați voci AI TikTok amuzante',
    description: 'Generați și descărcați gratuit voci AI TikTok amuzante, cum ar fi vocea jessie, vocea C3PO, vocea ghostface...',
    keywords: 'Generator de voce TikTok, Voce AI TikTok, Generator de voce Tik Tok',
    main_title_p01: 'Generator de voce TikTok',
    main_title_p02: 'Generați voci TikTok amuzante: vocea jessie, vocea C3PO, vocea ghostface',
    main_textarea_holder: 'Tastați sau lipiți textul aici',
    main_genvoice: 'generează',
    main_generating: 'generare',
    main_input_empty: 'vă rugăm să tastați sau să lipiți text',
    daily_usage_limit_msg: '1) Ați atins limita maximă de utilizare zilnică. Vă rugăm să reveniți mâine. 2) Dacă aveți nevoie urgentă de mai multe utilizări, vă rugăm să ne contactați prin e-mail.',
    text_max_prompt1: 'Vă rugăm să limitați numărul de cuvinte la',
    text_max_prompt2: 'cuvinte sau mai puține!',
    popup_nologin_title: 'Actualizați-vă planul de abonament',
    popup_nologin_desc: 'Deblocați mai mult timp de generare a vocii prin actualizarea la un abonament premium.',
    popup_nologin_btntext: 'Explorați planurile de abonament',
    popup_nosub_title: 'Actualizați-vă planul de abonament',
    popup_nosub_desc: 'Deblocați mai mult timp de generare a vocii prin actualizarea la un abonament premium.',
    popup_nosub_btntext: 'Explorați planurile de abonament',
    popup_sublimit_title: 'Abonament epuizat, vă rugăm să actualizați',
    popup_sublimit_desc: 'Îmbunătățiți-vă experiența cu funcțiile noastre premium și acces nelimitat.',
    popup_sublimit_btntext: 'Mergeți să cumpărați mai mult',
  },
  pricing: {
    new_features_alert: "📣 Am lansat servicii de 🎙️ Clonare Voce și 🤖 API pentru membrii plătitori! (După plată, trimiteți un e-mail la tiktokaivoicetool@gmail.com pentru ajutor cu aceste funcții). În plus, nu ezitați să ne trimiteți un e-mail pentru orice solicitare de personalizare😃",
    pricing_title: 'De la creatori individuali la cele mai mari întreprinderi, avem un plan pentru tine.',
    pricing_desc: 'Peste 100 de voci naturale, umane. Peste 15 limbi. și aproape cele mai mici prețuri pentru toată lumea',
    sub_free_name: 'Gratuit',
    sub_free_desc: 'Pentru persoanele care doresc să încerce cel mai avansat audio AI',
    sub_free_content: [
      'Limită de 1000 de caractere pe lună',
      '5 generări pe zi',
    ],
    sub_starter_name: 'Începător',
    sub_starter_desc: 'Pentru creatorii care produc conținut premium pentru o audiență globală',
    sub_starter_content: [
      '1 clonă de voce',
      'Limită de 300.000 de caractere pe lună (aproximativ 7 ore de audio)',
      'Descărcări nelimitate',
      'Suport prin e-mail în 72 de ore',
      'Acces prioritar la voci și funcții noi',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'Pentru creatorii care își extind producția de conținut',
    sub_pro_content: [
      'Acces API',
      '3 clone de voce',
      'Limită de 1.000.000 de caractere pe lună (aproximativ 24 de ore de audio)',
      'Descărcări nelimitate',
      'Suport prin e-mail în 48 de ore',
      'Acces prioritar la voci și funcții noi',
    ],
    period_year: 'Anual',
    period_month: 'Lunar',
    period_month_short: 'lună',
    billed_year: 'Facturat anual',
    most_popular: 'Cel mai popular',
    discount_quantity: 'Economisiți $48',
    buy_btntext: 'Începeți',
    pay_succ: 'Plată reușită!',
    pay_succ_desc: 'Vă mulțumim pentru achiziție. Tranzacția dvs. a fost procesată cu succes.',
    pay_succ_btntext: 'Începeți să utilizați TikTok Voice',
    pay_fail: 'Plata a eșuat!',
    pay_fail_desc: "Ne pare rău, nu am putut procesa tranzacția dvs. Vă rugăm să încercați din nou sau să contactați echipa noastră de asistență (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Înapoi la pagina principală',
  },
  setting: {
    setting: 'Setări',
    setting_title: 'Gestionați-vă contul, utilizarea și abonamentul aici.',
    basic_info: 'Informații de bază',
    user_name: 'Nume',
    user_email: 'E-mail',
    user_account: 'Cont',
    user_subscript: 'Plan curent',
    user_usage: 'Utilizare',
    manage_subscript: 'Gestionați abonamentul',
    use_limit_promote: "Ați utilizat 5000 din limita dvs. lunară de 10000 de caractere.",
    not_logged_in: 'Nu sunteți conectat',
    go_to_login: 'Mergeți la conectare >>',
  },
  login: {
    title: "Generator de Voce TikTok",
    subtitle: "Conectați-vă pentru a accesa contul dvs. de Generator de Voce TikTok",
    alreadyLoggedIn: "Sunteți deja conectat",
    goToHome: "Mergeți la pagina principală"
  },
  faq: {
    quest1: 'Generator de voce TikTok - Cel mai bun instrument de voce AI TikTok',
    answer1_1: 'Este un instrument generator de voce AI TikTok dezvoltat de cea mai recentă tehnologie tts TikTok, poate genera voci TikTok cum ar fi vocea jessie (voce feminină), vocea siri, vocea ghostface, vocea C3PO (voce robot), vocea profundă (povestitor), vocea skye, vocea caldă, vocea bestie, vocea eroului (vocea chiris), vocea empatică, vocea serioasă, vocea joey, vocea stitch, vocea Stormtrooper (Star Wars), vocea Rocket (Guardians of the Galaxy).',
    answer1_2: 'Vocile AI TikTok care vor fi suportate în curând includ: vocea derek, vocea trickster, vocea austin butler, vocea reporterului de știri, vocea adam, vocea câinelui, vocea miley cyrus, vocea alexa, vocea extraterestrului, vocea animalului, vocea bebelușului, vocea computerului, vocea chipmunk, vocea echo, vocea npr.',
    answer1_3: 'În plus, instrumentul generator de voce TikTok suportă mai multe alte limbi, inclusiv chineză, japoneză, coreeană, vietnameză, thailandeză, hindi, persană, rusă, germană, franceză, română, cehă, spaniolă, portugheză, bengaleză, italiană, arabă, urdu, chineză tradițională și malaieză.',
    answer1_4: 'Dacă aveți nevoie urgentă de o voce specifică, vă rugăm să-mi trimiteți un email.',

quest2: 'Care sunt avantajele generatorului de voce TikTok?',
    answer2_1: '- Instrumentul generator de voce TikTok poate genera diverse tipuri de voci, care sunt adesea folosite în videoclipurile TikTok.',
    answer2_2: '- Este cel mai recent generator de voce AI care poate genera voci tts TikTok asemănătoare cu cele umane.',
    answer2_3: '- Este mai convenabil pentru editarea videoclipurilor pe un PC.',
    answer2_4: '- Puteți folosi unele voci care nu se găsesc în prezent în aplicația TikTok tts.',

quest3: 'Cum se folosește generatorul de voce TikTok?',
    answer3_1: 'Generatorul de voce TikTok este foarte ușor de utilizat:',
    answer3_2: '- Alegeți limba și accentul vocii.',
    answer3_3: '- Tastați textul care urmează să fie convertit în voce în caseta de intrare.',
    answer3_4: '- Apăsați butonul de generare și așteptați câteva secunde.',
    answer3_5: '- Redați sau descărcați vocea AI TikTok.',

quest4: 'Care sunt cele mai celebre și amuzante voci TikTok?',
    answer4_1: 'Cea mai celebră voce masculină TikTok este vocea profundă, numită oficial povestitor.',
    answer4_2: 'Pentru cea mai amuzantă voce TikTok, recomand personal vocea ghostface și vocea C3PO. Acestea sunt adesea folosite pentru dublarea videoclipurilor amuzante TikTok deoarece sunt foarte ușor de recunoscut și pot atrage rapid spectatorii.',

quest5: 'Care sunt cele mai enervante voci TikTok?',
    answer5_1: 'Cred că nu există cu adevărat o voce TikTok "enervantă".',
    answer5_2: 'Este mai mult despre contextul și conținutul în care este folosită vocea care poate face ca unii oameni să se simtă inconfortabil. De exemplu, unii oameni pot găsi vocea jessie enervantă deoarece a apărut prea frecvent pe TikTok într-o anumită perioadă. Cu toate acestea, datorită timbrului și calității sale, este de fapt un efect de voce TikTok fantastic. În plus, actrița de voce TikTok din spatele acesteia este foarte profesionistă și își postează propriile videoclipuri pe TikTok.',
    answer5_3: 'Prin urmare, sugerez tuturor să lase deoparte îngrijorările și prejudecățile, să asculte de mai multe ori vocile din acest instrument și să găsească cu răbdare efectul de voce TikTok care vi se potrivește cel mai bine.',

quest6: 'Cum se folosește generatorul de voce TikTok pentru a genera vocea jessie?',
    answer6_1: '- În primul meniu derulant al instrumentului generator de voce TikTok, selectați English (US), apoi în celălalt meniu derulant, selectați vocea Jessie (voce feminină).',
    answer6_2: '- Tastați textul în caseta de intrare și faceți clic pe butonul de generare.',
    answer6_3: '- Așteptați câteva secunde sau până la zece secunde, veți auzi vocea AI. În bara de operații, găsiți butonul cu o săgeată în jos și faceți clic pe el pentru a descărca.',

quest7: 'Cum se folosește generatorul de voce TikTok pentru a genera vocea C3PO?',
    answer7_1: '- În primul meniu derulant al instrumentului generator de voce TikTok, selectați English (US), apoi în celălalt meniu derulant, selectați vocea C3PO (voce robot).',
    answer7_2: '- Tastați textul în caseta de intrare și faceți clic pe butonul de generare.',
    answer7_3: '- Așteptați câteva secunde sau până la zece secunde, veți auzi vocea AI. În bara de operații, găsiți butonul cu o săgeată în jos și faceți clic pe el pentru a descărca.',

quest8: 'Cum se folosește generatorul de voce TikTok pentru a genera vocea ghostface?',
    answer8_1: '- În primul meniu derulant al instrumentului generator de voce TikTok, selectați English (US), apoi în celălalt meniu derulant, selectați vocea ghostface.',
    answer8_2: '- Tastați textul în caseta de intrare și faceți clic pe butonul de generare.',
    answer8_3: '- Așteptați câteva secunde sau până la zece secunde, veți auzi vocea AI. În bara de operații, găsiți butonul cu o săgeată în jos și faceți clic pe el pentru a descărca.',

quest9: 'Cum se folosește generatorul de voce TikTok pentru a genera vocea siri?',
    answer9_1: '- În primul meniu derulant al instrumentului generator de voce TikTok, selectați English (US), apoi în celălalt meniu derulant, selectați vocea siri.',
    answer9_2: '- Tastați textul în caseta de intrare și faceți clic pe butonul de generare.',
    answer9_3: '- Așteptați câteva secunde sau până la zece secunde, veți auzi vocea AI. În bara de operații, găsiți butonul cu o săgeată în jos și faceți clic pe el pentru a descărca.',

quest10: 'Cum se adaugă efectul de voce TikTok la un videoclip TikTok?',
    answer10_1: 'Dacă doriți să știți cum să utilizați vocile oficiale TikTok pe TikTok, voi scrie în curând un articol dedicat pe acest subiect.',
    answer10_2: 'Aici voi explica cum să publicați vocea dvs. pe TikTok după ce ați generat-o și descărcat-o din generatorul de voce TikTok.',
    answer10_3: '1. Dacă ați generat vocea TikTok pe PC-ul dvs. și doriți să o încărcați pe TikTok sau orice altă aplicație de editare video de pe telefonul dvs., trebuie să transferați fișierul vocal pe telefonul dvs. Pentru iPhone, puteți utiliza airDrop pentru a transfera. Pentru telefoanele Android, nu sunt foarte familiarizat cu acest proces, dar cu siguranță veți găsi metode și instrumente pentru a face acest lucru.',
    answer10_4: '2. Odată ce fișierul vocal este transferat pe telefonul dvs., puteți deschide TikTok:',
    answer10_5: '- Apăsați pe butonul "+" din partea de jos a interfeței, selectați videoclipul de pe telefonul dvs.',
    answer10_6: '- După ce ați încărcat videoclipul, găsiți o pictogramă pătrată în partea dreaptă a ecranului pentru a accesa pagina de editare.',
    answer10_7: '- În partea de jos a paginii, găsiți butonul "adăugați voce" și apăsați pentru a selecta fișierul vocal tocmai transferat.',
    answer10_8: '- În această interfață, puteți face câteva ajustări la videoclip, apoi apăsați pe butonul din colțul din dreapta sus pentru a-l publica.',

quest11: 'TikTok Voice este gratuit?',
    answer11: 'Da, este un instrument gratuit de generare a vocii AI TikTok din text în voce.',

quest12: 'Unde pot genera o voce TikTok populară în videoclipul meu TikTok?',
    answer12: 'Puteți vizita https://tiktokvoice.net/ pentru a adăuga voci AI TikTok în videoclipul dvs.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}